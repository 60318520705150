import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import ProcessRichText, {
  ImageNonWrapperStyles,
} from '../utils/processRichText'
import CategoryList from './CategoryList'

const EventGrid = styled.div`
  h1 {
  }

  .legacy-container {
    width: 80%;
    margin: 30px auto;

    img {
      margin: 0 auto;
    }
  }

  .event-image {
    /* width: 80%; */
    margin: 30px auto;
  }
`

const EventImage = ({ image }) => {
  const { asset, imageOptions, alt } = image

  const imageDiv = (
    <ImageNonWrapperStyles stats={imageOptions} className="event-image">
      <div className="image-container">
        <Img fluid={asset.fluid} alt={alt} />
      </div>
    </ImageNonWrapperStyles>
  )

  return imageDiv
}

export default function SingleEventPage({ data }) {
  const { event } = data

  const MainImage = event?.eventImage ? (
    <EventImage image={event.eventImage} />
  ) : (
    <div className="image-container">
      <Img fluid={event.legacyImage.asset.fluid} alt={event.title} />
    </div>
  )

  return (
    <>
      <EventGrid className="content-width">
        <h1>{event.title}</h1>
        {MainImage}
        <ProcessRichText curProp={event} />
        <CategoryList categories={event.categories} baseUrl="/events" />
      </EventGrid>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    event: sanityEvent(slug: { current: { eq: $slug } }) {
      title
      id
      eventDate
      legacyImage: image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      eventImage: eventImage {
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
        imageOptions {
          alignment
          maxWidth
        }
      }
      content: _rawContent
      categories {
        id
        name
        color {
          hex
        }
        invert
      }
    }
  }
`
